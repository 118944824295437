<template>
    <div>    
        
        <div class="text-sm text-gray-500 mb-10">
            Register company employees. User accounts would be created automatically.
        </div>
        
        <template v-if="!preview">

            <form @submit.prevent="submitEmployees" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16" key="admin">

                <template v-if="formErrorMessage">
                    <div class="rounded-full bg-red-100 p-3 flex flex-row items-center mb-10">
                        <div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
                            !
                        </div>
                        <div class="text-xs font-normal">{{ formErrorMessage }}</div>
                    </div>
                </template>

                <div class="flex flex-row justify-between">
                    <div class="text-sm mb-4">Upload Excel or CSV File </div>
                    <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a>
                </div>

                <dropzone accept=".xlsv, .xlsx, .csv" v-model="form.data.sheet.value" :uploading="form.uploading" :uploaded="form.uploaded"></dropzone>

                <template v-if="bulkFieldErrors">
                    <div class="text-red-500 text-sm mb-5">Please fix the following errors and try again.</div>

                    <div class="border border-blue-200 rounded text-xs mb-10 break-all">

                        <template v-for="(row, n) in bulkFieldErrors">
                            <div :key="n">
                                <div :class="{'border-t border-blue-200': n != 0}">
                                    <div class="border-b border-blue-200 px-3 py-2">
                                        Row: {{ row.index }}
                                    </div>
                                    <div class="grid grid-cols-3">
                                        <div class="col-span-1 px-3 py-2">
                                            <div class="mb-2">{{ row.data.name }}</div>
                                            <div v-if="row.errors.name" class="text-red-500">{{ row.errors.name[0] }}</div>
                                        </div>
                                        <div class="col-span-1 px-3 py-2 border-l border-blue-200">
                                            <div class="mb-2">{{ row.data.email }}</div>
                                            <div v-if="row.errors.email" class="text-red-500">{{ row.errors.email[0] }}</div>
                                            
                                        </div>
                                        <div class="col-span-1 px-3 py-2 border-l border-blue-200">
                                            <div class="mb-2">{{ row.data.phone }}</div>
                                            <div v-if="row.errors.phone" class="text-red-500">{{ row.errors.phone[0] }}</div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>

                </template>

                <div class="flex flex-row items-center justify-end">
                    <button
                        class="text-sm text-gray-500 mr-10"
                        @click.prevent="skip"
                    >
                        Skip for later
                    </button>
                    <button
                        type="button"
                        class="button bg-gray-100 hover:bg-gray-300 text-dark mr-10"
                        :disabled="!sheetRows || !sheetRows.length || form.uploading"
                        @click.prevent="showPreview"
                    >
                        Preview
                    </button>
                    <button
                        type="submit"
                        class="button bg-blue-500 hover:bg-blue-600 text-white"
                        :disabled="!sheetRows || !sheetRows.length || form.uploading"
                    >
                        Upload
                    </button>
                </div>

            </form>

        </template>

        <template v-else>
            <!-- 
            <div class="mb-7">
                <button type="button" class="text-blue-500 text-sm focus:outline-none" @click.prevent="back">
                    <span class="caret caret-left inline-block"></span> Back
                </button>
            </div>
             -->
            <div class="flex">
                <div class="w-full">
                    <h1 class="text-2xl font-bold mb-5">Adding Multiple Employees</h1>

                    <div class="text-sm xl:w-7/10 mb-10">
                        Have a glance though the form.uploaded data for a quick review before proceeding.
                    </div>

                    <div class="border border-blue-200 mb-5">
                        <table class="table table-fixed w-full">
                            <thead>
                                <tr>
                                    <th class="w-1/2">Staff Name</th>
                                    <th class="w-1/2">Email Address</th>
                                    <th class="w-1/2">Phone Number</th>
                                </tr>
                            </thead>
                        </table>
                        <div class="max-h-500px overflow-y-auto">
                            <table class="table table-fixed w-full">
                                <tbody>
                                    <template v-for="(row, n) in sheetRows">
                                        <tr :key="n">
                                            <td class="w-1/3">
                                                <template v-if="row.name">
                                                    {{ row.name }}
                                                </template>
                                                <template v-else>
                                                    <div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
                                                        <div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
                                                            !
                                                        </div>
                                                        <span>No name provided</span>
                                                    </div>
                                                </template>
                                            </td>
                                            <td class="w-1/3">
                                                <template v-if="row.email">
                                                    {{ row.email }}
                                                </template>
                                                <template v-else>
                                                    <div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
                                                        <div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
                                                            !
                                                        </div>
                                                        <span>No email provided</span>
                                                    </div>
                                                </template>
                                            </td>
                                            <td class="w-1/3">
                                                <template v-if="row.phone">
                                                    {{ row.phone }}
                                                </template>
                                                <template v-else>
                                                    <div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
                                                        <div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
                                                            !
                                                        </div>
                                                        <span>No phone provided</span>
                                                    </div>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="text-right">
                        <button type="button" class="text-blue-500 text-sm focus:outline-none mr-16" @click.prevent="back">
                            <span class="caret caret-left inline-block"></span> Back
                        </button>
                        <button class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="submitEmployees">Submit</button>
                    </div>

                </div>
            </div>

        </template>

        <modal className="w-full md:w-2/5 xl:w-3/10 px-10 py-10" ref="tipModal">
            <div class="text-lg font-bold mb-4">
                The file format you need
            </div>

            <div class="mb-4 text-sm text-gray-500">Shown below is the compatible format for adding bulk employees.</div>

            <img :src="sheetPreview" alt="Preview" class="w-full mb-8">

            <div class="flex flex-row">
                <div class="w-5px h-5px bg-blue-500 mr-2 mt-5px" style="border-radius: 50%; flex: 0 0 5px"></div>
                <div class="text-xs text-gray-500 font-hairline">
                    When done, you would have to save the file as an Excel File (.xls / .xlsx) or as a CSV File (.csv).
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
    import XLSX from 'xlsx';

    export default {
        data() {
            return {
                preview: false,
                sheetRows: null,
                form: this.$options.basicForm([
                    {name: 'sheet', value: null}
                ], {
                    uploading: false,
                    uploaded: false,
                }),
                sheetPreview: require('@/assets/sheet-preview-2.png'),
            }
        },
        computed: {
            formErrorMessage() {
                if (this.form.error.toString().match(/Error: Network Error/i)) {
                    return 'Please check your internet connection';
                }

                const errors = this.form.error?.response?.data?.errors;
                if (errors && Object.keys(errors).length) {
                    return 'Please check the form for incorrect or missing data';
                }

                return null;
            },
            bulkFieldErrors() {
                const errors = this.form.error?.response?.data?.errors;
                var bulkFieldErrors = null;

                if (errors && Object.keys(errors).length) {
                    bulkFieldErrors = {};

                    for (let key in errors) {
                        let match = key.match(/staff.(\d+).(\w+)/);
                        console.log(match)
                        let index = match?.[1];
                        let name = match?.[2];
                        let error = errors[key];

                        // bulkFieldErrors.push({ data: this.sheetRows[index], column: name, error  });
                        if (bulkFieldErrors[index]) {
                            bulkFieldErrors[index].errors[name] = error;
                        }else {
                            bulkFieldErrors[index] = {
                                index,
                                data: this.sheetRows[index],
                                errors: {
                                    [name]: error
                                }
                            }
                        }

                    }
                }

                return bulkFieldErrors;
            },
            fileExtension() {
                const sheet = this.form.data.sheet.value;
                if (!sheet) {
                    return null;
                }

                const extensions = {
                    csv: [/.csv$/i],
                    excel: [/.xls$/i]
                };

                for (let extension in extensions) {
                    if (extensions[extension].find( expression => sheet.name.match(expression) )) {
                        return extension;
                    }
                }

                return null;
            },
        },
        watch: {
            'form.data.sheet.value'(data) {
                if (!data) {
                    this.sheetRows = null;
                    return null;
                }

                const Reader = new FileReader();

                Reader.onload = (event) => {
                    
                    const workBook = XLSX.read(event.target.result, { type: 'binary' });

                    const sheet = workBook.Sheets[workBook.SheetNames[0]];
                    const rows = XLSX.utils.sheet_to_json(sheet);

                    this.sheetRows = rows.map( row => {
                        const data = {};

                        data.name = row[Object.keys(row)[0]];
                        data.email = row[Object.keys(row)[1]];
                        data.phone = row[Object.keys(row)[2]];
                        data.role = 'company_staff';
						data.group = null;

                        // for (let column in row) {
                        //     let columnName = column.toLowerCase().replace(/\s/, '_')
                        //     data[columnName] = row[column];
                        // }
                        return data;
                    } )

                }

                Reader.readAsBinaryString(data);

            }
        },
        methods: {
            skip() {
                this.$emit('skip');
            },
            back() {
                this.preview = false;
            },
            help() {
                this.$refs.tipModal.open();
            },
            showPreview() {
                this.preview = true;
            },
            async getStaff(forced) {
                this.loadResource('staff', forced);
            },
            async submitEmployees() {
                this.preview = false;

                // const data = new FormData();

                // data.append('file', this.form.data.sheet.value);

                this.form.uploading = true;

                await this.$post({
                    url: `${this.$baseurl}/companies/staff/addBulk`,
                    data: {
                        staff: this.sheetRows
                    },
                    headers: this.headers,
                    success: () => {
                        this.form.uploaded = true;
                        this.$emit('success');
                        this.getStaff(true);
                    },
                    error: (error) => {
                        this.form.error = error;
                    },
                    axiosProps: {
                        onUploadProgress: (event) => {
                            console.log(event);
                        },
                        onDownloadProgress: (event) => {
                            console.log(event);
                        },
                    }
                });

                this.form.uploading = false;
            },
        },
    }
</script>